import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["error"];
  
  validate(e) {
    const pinField = this.targets.find("pin");
    if (pinField && !pinField.value.match(/^\d{3}$/)) {
      e.preventDefault()
      this.errorTarget.hidden = false;
      return false
    }
  }
  half_number(e) {
    const newvalue = e.target.value.replace(/[０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    e.target.value = newvalue;
  }
}
