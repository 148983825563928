import $ from "jquery";
import "jquery.qrcode";

import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    const size = this.data.get("size");
    const text = this.data.get("text");

    $(this.element).qrcode({ width: size, height: size, text });
  }
}
