// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import Stickyfill from "stickyfilljs";
import "../controllers";

require("../library/pickerdate/picker");
require("../library/pickerdate/picker.date");

document.addEventListener("turbolinks:before-cache", () => {
  document.querySelector("#header-sidebar").classList.remove("is-active");
});

$(document).on("turbolinks:load", function () {
  const sidebar_button = $("#header-sidebar-button");
  const sidebar = $("#header-sidebar");
  sidebar_button.on("click", function () {
    sidebar_button.toggleClass("is-active");
    sidebar.toggleClass("is-active");
  });


  // カレンダーのlang
  const languages = {
    'ja': {
      monthsFull: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      monthsShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      weekdaysFull: ['日曜', '月曜', '火曜', '水曜', '木曜', '金曜', '土曜'],
      weekdaysShort: ['日曜', '月曜', '火曜', '水曜', '木曜', '金曜', '土曜'],
      today: '本日',
      clear: 'キャンセル',
      format: 'yyyy/mm/dd'
    },
    'zh-CN': {
      monthsFull: [ '一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月' ],
      monthsShort: [ '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二' ],
      weekdaysFull: [ '星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六' ],
      weekdaysShort: [ '日', '一', '二', '三', '四', '五', '六' ],
      today: '今天',
      clear: '清除',
      close: '关闭',
      format: 'yyyy/mm/dd'
    }
  }
  const select_language = $(".is-calendar").data('language');

  // カレンダーを作成する
  const picker = $(".is-calendar").pickadate(languages[select_language]).pickadate("picker");
  if (picker) {
    picker.set("min", 0).set("max", 7);
  }

  $("#confirm-overlay-button").on("click", function () {
    $("#confirm-overlay").hide();
  });

  Stickyfill.add(document.querySelectorAll(".sticky"));
});
