import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["prices", "tags"];

  isValid() {
    return this.pricesTargets.every(
      (price, i) => price.value || !this.tagsTargets[i].checked
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.isValid()) {
      e.target.submit();
    } else {
      alert("金額を入力してください");
    }
  }
}
