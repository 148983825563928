import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["alert", "confirm"];

  alert() {
    this.alertTarget.hidden = false;
  }

  closeAlert() {
    this.alertTarget.hidden = true;
  }

  confirm() {
    this.confirmTarget.hidden = false;
  }
}
