import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  initialize() {
    this.buttonTarget.classList.add("is-disable");
  }

  check(e) {
    if (e.target.checked) {
      this.buttonTarget.classList.remove("is-disable");
    } else {
      this.buttonTarget.classList.add("is-disable");
    }
  }
}
