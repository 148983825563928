import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  validate(e) {
    if (e.target.value === "") {
      this.buttonTarget.classList.add("is-disable");
    } else {
      this.buttonTarget.classList.remove("is-disable");
    }
  }
}
